






























import Vue from 'vue';
import DescriptionCol from '@/components/list/DescriptionCol.vue';
import ListWithFilter from '@/components/list/ListWithFilter.vue';
import SecurityLevelCol from '@/components/list/SecurityLevelCol.vue';
import UploadFiles from '@/views/projects/files/UploadFiles.vue';

let timeout: any = null;

export default Vue.extend({
  name: 'ListFiles',
  components: {
    UploadFiles,
    SecurityLevelCol,
    ListWithFilter,
    DescriptionCol,
  },
  computed: {
    searchUrl(): string {
      return `/project/${this.projectId}/files/info`;
    },
    viewUrl(): string {
      return `/org/${this.orgId}/project/${this.projectId}/file`;
    },
    deleteUrl(): string {
      return `/project/${this.projectId}/file/remove`;
    },
    orgId(): string {
      return this.$route.params.orgId;
    },
    projectId(): string {
      return this.$route.params.projectId;
    },
  },
  data() {
    return {
      extraColumns: [
        {
          name: 'description',
          field: 'description',
          label: 'Description',
          align: 'left',
          sortable: false,
        },
        {
          name: 'size',
          label: 'Size',
          field: 'size',
          align: 'center',
          sortable: true,
        },
        {
          name: 'securityLevel',
          label: 'Security Level',
          field: 'securityLevel',
          align: 'center',
          sortable: true,
        },
        {
          name: 'version',
          field: 'version',
          label: 'Version',
          align: 'left',
          sortable: false,
        },
        {
          name: 'format',
          label: 'Format',
          field: 'format',
          align: 'center',
          sortable: true,
        },
        {
          name: 'uses',
          label: 'Uses',
          field: 'uses',
          align: 'center',
          sortable: true,
        },
      ],
    };
  },
  methods: {
    onSubmit(handler: () => void) {
      clearTimeout(timeout as any);
      timeout = setTimeout(handler, 500);
    },
    fileSizeInMb(size: number) {
      return `${(size / (1024 * 1024)).toFixed(2)}Mb`;
    },
  },
});
